<template>
<div class="text-3xl text-center m-10">
    Regulamin
</div>
<div class="list-decimal m-3">
    <li class="m-3 text-xl">
       Podstawowe ustalenia 
    </li>
        <div class="list-disc m-2">
            <li>
                Ten regulamin określa zasady zapisu ucznia na kurs w firmie M27 Łukasz Rumak. Osoba zapisująca zwana jest dalej płatnikiem.
            </li>
            <li>
                Na zajęcia prowadzone przez firmę M27 Łukasz Rumak uczęszczają zapisani przez płatników uczniowie, zwani dalej kursantami.
            </li>
        </div>       
    <li class="m-3 text-xl">
       Zapisy na kurs 
    </li>
    <div class="list-disc m-2">
        <li>
            Podstawowym warunkiem uczestnictwa w zajęciach jest akceptacja niniejszego regulaminu i Podstawowym warunkiem uczestnictwa w zajęciach jest akceptacja niniejszego regulaminu i dokonanie opłaty.
        </li>
        <li>
            Zapisów dokonuje się drogą elektroniczną za pomocą formularza na stronie internetowej https://edukacja.m27.pl
        </li>
        <li>
           Przed rozpoczęciem kursu konieczne jest:
           <div class="m-3">
               <div>
                  a. opłacenie pierwszej raty za zajęcia.
               </div>
               <div>
                  b. zatwierdzenie regulaminu za pomocą formularza na stronie internetowej 
               </div>
           </div>
        </li>
        <li>
            W przypadku zapisania się na kurs, który już się rozpoczął, opłata za kurs jest pomniejszona o koszt zajęć, które się już odbyły.
        </li>
        <li>
             Dopóki płatnik nie zaakceptuje tego regulaminu i nie dokona płatności za pierwszy miesiąc kursant nie może uczęszczać na zajęcia.
        </li>        
    </div> 
    <li class="m-3 text-xl">
       Organizacja zajęć
    </li>
    <div class="list-disc m-2">
        <li>Pojedyncze zajęcia trwają 60 min</li>
        <li>M27 Łukasz Rumak gwarantuje, że zajęcia będą prowadzone wyłącznie przez wykwalifikowanych instruktorów.</li>
        <li>M27 Łukasz Rumak zobowiązuje się do dołożenia wszelkich starań w celu zapewnienia najwyższego poziomu nauczania, do kontaktu z płatnikami w przypadku ewentualnych problemów wychowawczych.</li>
        <li>Podstawowym sposobem kontaktu z kursantem i płatnikiem w celu poinformowania o ewentualnych zmianach w organizacji zajęć jest kontakt telefoniczny (781-470-501) lub za pomocą poczty elektronicznej kontakt@m27.pl</li>
    </div>
</div>
</template>