<template>
<div class="text-3xl m-4 text-center ">
    Polityka prywatności
</div>
<div class="list-decimal m-3">
    <li class="m-3 text-xl">
       Informacje ogólne 
    </li>
        <div class="text-md m-2">
        Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: edukacja.m27.pl
        Operatorem serwisu oraz Administratorem danych osobowych jest: M27 Łukasz Rumak ul. Orlanda 10,20-712, Lublin
        Adres kontaktowy poczty elektronicznej operatora: kontakt@m27.pl
        Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.
        Serwis wykorzystuje dane osobowe w następujących celach:
            <div class="list-disc m-2">
                <li>
                    Obsługa zapytań przez formularz
                </li>
                <li>
                    Realizacja zamówionych usług
                </li>
            </div>       
            Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:
            <div class="list-disc m-2">
                <li>
                    Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.
                </li>
                <li>
                    Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).
                </li>
            </div>
        </div>
    <li class="m-3 text-xl">
       Wybrane metody ochrony danych stosowane przez Operatora 
    </li>
    <div class="list-disc m-2">
        <li>
            Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.
        </li>
        <li>
            Dane osobowe przechowywane w bazie danych są zaszyfrowane w taki sposób, że jedynie posiadający Operator klucz może je odczytać. Dzięki temu dane są chronione na wypadek wykradzenia bazy danych z serwera.
        </li>
        <li>
            Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja hashująca działa jednokierunkowo - nie jest możliwe odwrócenie jej działania, co stanowi obecnie współczesny standard w zakresie przechowywania haseł użytkowników.
        </li>
        <li>
            W serwisie jest stosowana autentykacja dwuskładnikowa, co stanowi dodatkową formę ochrony logowania do Serwisu.
        </li>
        <li>
            Operator okresowo zmienia swoje hasła administracyjne.
        </li>        
        <li>
            W celu minimalizacji ryzyka nieuprawnionego dostępu od danych, Operator stosuje hasła złożone, zawierające małe i wielkie litery, cyfry oraz znaki specjalne, nie krótsze niż 8 znaków.
        </li>
    </div> 
    <li class="m-3 text-xl">
       Hosting 
    </li>
    <div class="m-2">
        Serwis jest hostowany (technicznie utrzymywany) na serwera operatora: inna firma
    </div>
    <li class="m-3 text-xl">
       Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych
    </li>
    <div class="m-2">
    W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:
    upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu realizacji celu działania strony
    Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to konieczne do wykonania związanych z nimi czynności określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.
    Przysługuje Ci prawo żądania od Administratora:
    </div>
    <div class="list-disc m-2">
        <li>dostępu do danych osobowych Ciebie dotyczących</li>
        <li>ich sprostowania</li>
        <li>usunięcia</li>
        <li>ograniczenia przetwarzania</li>
        <li>oraz przenoszenia danych</li>
    </div>
    <div class="m-2">
    Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony roszczeń.    
    </div>
    <div class="m-2">
        Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.    
    </div>
    <div class="m-2">
    Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.    
    </div>
    <div class="m-2">
    W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.    
    </div>
    <div class="m-2">
    Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii Europejskiej.    
    </div>
    <li class="m-3 text-xl">
       Informacje w formularzach
    </li>
        <div class="m-2">
            Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.
        </div>
        <div class="m-2">
            Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).
        </div>
        <div class="m-2">
            Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.
        </div>
        <div class="m-2">
            Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do czego on służy.
        </div>
    <li class="m-3 text-xl">
       Logi Administratora
    </li>
        <div class="m-2">
            Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.
        </div>
    <li class="m-3 text-xl">
       Informacja o plikach cookies
    </li>
        <div class="m-2">
            Serwis korzysta z plików cookies.
        </div>
        <div class="m-2">
            Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
        </div>
        <div class="m-2">
            Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer. Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
        </div>
        <div class="m-2">
            Pliki cookies wykorzystywane są w następujących celach:
        </div>
        <div class="list-disc m-2">
            <li>
                utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła
            </li>
            <li>
                utrzymywanie parametrów strony wybranych przez użytkownika (kolorystyka, język, motyw itp)
            </li>
        </div>
        <div class="m-2">
            W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
        </div>
        <div class="m-2">
            Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.
        </div>
        <div class="m-2">
            Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.
        </div>
        <div class="m-2">
            Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).
        </div>
    <li class="m-3 text-xl">
       Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?
    </li>
        <div class="m-2">
            Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.
        </div>
        <div class="m-2">
            W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową, której używasz i postępuj zgodnie z instrukcjami:
        </div>
        <div class="list-disc m-2">
            <li>
                Edge
            </li>
            <li>
                Internet Explorer
            </li>
            <li>
                Chrome
            </li>
            <li>
                Safari
            </li>
            <li>
                Firefox
            </li>
            <li>
                Opera
            </li>
        </div>
        <div class="m-2">
            Urządzenia mobilne
        </div>
        <div class="list-disc m-2">
            <li>
                Android
            </li>
            <li>
                Safari (iOS)
            </li>
            <li>
                Windows Phone
            </li>
        </div>
</div>
</template>