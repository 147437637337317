<template>
<!-- This example requires Tailwind CSS v2.0+ -->
<div class="mb-12 bg-white">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="lg:text-center">
      <h2 class="text-5xl text-red-600 font-bold tracking-wide uppercase">RODZICU</h2>
      <p class="mt-4 text-3xl leading-8 font-extrabold tracking-tight text-red-600 sm:text-4xl">
        Postaw na kreatywność swojego dziecka!
      </p>
      <p class="mt-6 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        To ona pomoże mu odnaleść się w świecie, który ciągle się zmienia.
      </p>
    </div>

    <div class="my-12">
      <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        <div class="flex">
          <div class="flex-shrink-0">
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
              <!-- Heroicon name: globe-alt -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>
            </div>
          </div>
          <div class="ml-4">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              Myślenie wizualne
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Jak za pomocą prostych obrazków planować swoje filmy.
            </dd>
          </div>
        </div>

        <div class="flex">
          <div class="flex-shrink-0">
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
              <!-- Heroicon name: scale -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
              </svg>
            </div>
          </div>
          <div class="ml-4">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              Reklama
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Co sprawia, że kupujemy to co kupujemy, razem stworzymy reklamę telewizyjną.
            </dd>
          </div>
        </div>

        <div class="flex">
          <div class="flex-shrink-0">
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
              <!-- Heroicon name: lightning-bolt -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
            </div>
          </div>
          <div class="ml-4">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              Filmowanie
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Jak nagrywać ciekawe filmy, które przykują uwagę widzów.
            </dd>
          </div>
        </div>

        <div class="flex">
          <div class="flex-shrink-0">
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
              <!-- Heroicon name: annotation -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </div>
          </div>
          <div class="ml-4">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              Sztuka samoprezencji
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Jak mówić by nas słuchano? Jak słuchać by wiedzieć co mówić?
            </dd>
          </div>
        </div>

        <div class="flex">
          <div class="flex-shrink-0">
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
              <!-- Heroicon name: lightning-bolt -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
              </svg>
            </div>
          </div>
          <div class="ml-4">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              Sekrety  fotografi
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Współnie przeprowadzimy profejonalną sesję zdjęciową.
            </dd>
          </div>
        </div>

        <div class="flex">
          <div class="flex-shrink-0">
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
              <!-- Heroicon name: lightning-bolt -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
              </svg>
            </div>
          </div>
          <div class="ml-4">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              Edycja video
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Sztuka płynnego łączenia obrazu i dźwięku. Edytory filmowe.
            </dd>
          </div>
        </div>   

        <div class="flex">
          <div class="flex-shrink-0">
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
              <!-- Heroicon name: lightning-bolt -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
              </svg>
            </div>
          </div>
          <div class="ml-4">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              Bezpieczeństwo to podstawa
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Jak o nie zadbać? Na co uważać w internecie? Jak się zabezpieczyć?
            </dd>
          </div>
        </div>  

        <div class="flex">
          <div class="flex-shrink-0">
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-600 text-white">
              <!-- Heroicon name: lightning-bolt -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </div>
          </div>
          <div class="ml-4">
            <dt class="text-lg leading-6 font-medium text-gray-900">
              Grafika
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Dobrze zaprojektowana miniatura filmu to podstawa.
            </dd>
          </div>
        </div>
      </dl>
    </div>
    <div class="lg:text-center m-10 ">
      <h2 class="mt-4 text-3xl leading-8 font-extrabold tracking-tight text-red-600 sm:text-4xl">Najczęściej zadawane pytania</h2>
    </div >
         <div v-for="pytanie in pytania" :key="pytanie.id">
          <div class=" m-8 lg:text-left">
            <div class="m-4">
              <dt class="text-lg leading-6 font-medium text-gray-900">
                {{pytanie.pyt}}
              </dt>
              <dd class="m-2 lg:text-left text-base text-gray-500">
                {{pytanie.odp}}
              </dd>
            </div>
          </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            pytania: [
              {
                    id: "1",
                    pyt: "Dla kogo przeznaczony jest kurs?",
                    odp: "Kurs przeznaczony jest dla dzieci z klas 1-6, które uwielbiają kreatywne działania. Chcą nauczyć się tego, jak prowadzić swój własny kanał na YouTube."
              },
              {
                    id: "2",
                    pyt: "Gdzie odbywają się zajęcia?",
                    odp: "Zajęcia odbywają się w kilku lokalizacjach. Sprawdź mapę, gdzie masz najbliżej."
              },
              {
                    id: "3",
                    pyt: "Jak długo trwają zajęcia?",
                    odp: "Zajęcia trwają pełną godzinę zegarową. Podczas której dzieci nabywają wiedzę teoretyczną oraz praktyczną na wiele ciekawych tematów. Sprawdź zakładkę (o kursie), gdzie dowiesz się, czego może nauczyć się twoje dziecko."
              },
              {
                    id: "4",
                    pyt: "Dla kogo przeznaczony jest kurs?",
                    odp: "Kurs przeznaczony jest dla dzieci z klas 1-6, które uwielbiają kreatywne działania. Chcą nauczyć się tego, jak prowadzić swój własny kanał na YouTube."
              },
              {
                    id: "6",
                    pyt: "Ile kosztuje kurs?",
                    odp: "Cena regularna kursu to 229 zł za 4 spotkania w miesiącu, lecz do końca lutego obowiązuje promocja 149 zł."
              },
              {
                    id: "7",
                    pyt: "Jak mogę dokonać płatności?",
                    odp: "Płatności można dokonać za pomocą przelewu, szybkiej płatności przelewy 24, lub gotówką."
              },
              {
                    id: "8",
                    pyt: "Czy dziecko musi mieć własny sprzęt? (kamera, laptop).?",
                    odp: "Nie musi, my zapewniamy wszelki potrzebny sprzęt. Jednak rekomendujemy korzystanie z własnego sprzętu, dzięki czemu dziecko od razu uczy się na swoim."
              },
            ],
        }
    }, 
}
</script>